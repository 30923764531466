import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { CUSTOM_ICONS_TYPE, Card, DetailAction } from 'src/app/typings';
import { TranslocoDirective } from '@jsverse/transloco';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { InfoComponent } from '../info/info.component';
import { RoadComponent } from '../road/road.component';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { BreakpointService } from 'src/app/core/services';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'cool-detail',
  standalone: true,
  imports: [
    SvgIconComponent,
    TranslocoDirective,
    NzButtonModule,
    NgClass,
    RoadComponent,
    InfoComponent,
    NgTemplateOutlet,
  ],
  templateUrl: './detail.component.html',
  styleUrl: './detail.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailComponent {
  card = input<Card>();
  actions = input<DetailAction[]>(this.defaultActions());
  isMobile = toSignal(this.bpService.isMobile$);
  icon = computed(() => this.defineIcon());

  constructor(private bpService: BreakpointService) {}

  private defineIcon(): CUSTOM_ICONS_TYPE {
    switch (this.card()?.type) {
      case 'flight': {
        return 'c_airplane';
      }
      case 'bus': {
        return 'c_bus';
      }
      case 'car': {
        return 'c_car';
      }
      case 'ship': {
        return 'c_ship';
      }
    }
  }

  private defaultActions(): DetailAction[] {
    return [
      {
        title: 'partner.ticket',
        dark: true,
      },
      {
        title: 'partner.booking',
        dark: false,
      },
      {
        title: 'partner.hostelworld',
        dark: false,
      },
    ];
  }
}
