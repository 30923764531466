import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { BRAND } from '../../../constants';


@Component({
  selector: 'cool-logo',
  standalone: true,
  imports: [TranslocoModule],
  templateUrl: './logo.component.html',
  styleUrl: './logo.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  brand = signal<BRAND>(BRAND.cooltrip);
}
