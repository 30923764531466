import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NzImageModule } from 'ng-zorro-antd/image';

import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { Card } from '../../../typings';
import { NgClass } from '@angular/common';
import { ClickDirective } from '../../directives';
import { InfoComponent, PriceComponent, RoadComponent } from '../';

@Component({
  selector: 'cool-card',
  standalone: true,
  imports: [
    NzImageModule,
    SvgIconComponent,
    TranslocoModule,
    NgClass,
    ClickDirective,
    PriceComponent,
    RoadComponent,
    InfoComponent
  ],
  templateUrl: './card.component.html',
  styleUrl: './card.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  card = input<Card>();
  small = input<boolean>();
  disabled = input<boolean>();
  learn = input<boolean>();
  clicked = output<Card>();
}
