export enum SUCCESS_MESSAGE {
  lang = 'messages.success.lang',
  theme = 'messages.success.theme',
  found = 'messages.success.found',
}

export enum INFO_MESSAGE {
  change = 'messages.info.change',
}

export enum ERROR_MESSAGE {
  server = 'messages.error.server',
  lang = 'messages.error.lang',
  theme = 'messages.error.theme',
  found = 'messages.success.found',
}
