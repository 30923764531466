import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslocoService } from '@jsverse/transloco';
import { first, Observable, tap } from 'rxjs';
import { BRAND, LANGUAGE } from '../../constants';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  get documentEl(): HTMLElement {
    return this.document.documentElement;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private meta: Meta,
    private title: Title,
    private translocoService: TranslocoService,
  ) {}

  updateTitle$(): Observable<string> {
    return this.translocoService.selectTranslate('title').pipe(
      first(),
      tap((title) => {
        this.title.setTitle(`${BRAND.cooltrip} - ${title}`);
        this.meta.updateTag({
          name: 'description',
          content: title,
        });
      }),
    );
  }

  updateLocale(lang: LANGUAGE): void {
    this.meta.updateTag({
      name: 'locale',
      content: lang,
    });

    this.meta.updateTag({
      'http-equiv': 'Content-Language',
      content: lang,
    });

    this.documentEl.lang = lang;
  }
}
