import { ChangeDetectionStrategy, Component, forwardRef, input, model } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NzSizeLDSType, NzValidateStatus } from 'ng-zorro-antd/core/types';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';


import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { FunctionType } from '../../../typings';

@Component({
  selector: 'cool-input',
  standalone: true,
  imports: [FormsModule, NzFormModule, NzInputModule, SvgIconComponent, TranslocoModule],
  templateUrl: './input.component.html',
  styleUrl: './input.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements ControlValueAccessor {
  value = model<string>('Tashkent, Uzbekistan');
  placeholder = input<string>();
  blocked = model<boolean>();
  size = input<NzSizeLDSType>('large');
  autocomplete = input<string>();
  status = input<NzValidateStatus>('');
  readonly = input<boolean>();

  onChange: FunctionType = () => {};
  onTouched: FunctionType = () => {};

  writeValue(value: string): void {
    this.value.set(value);
  }

  registerOnChange(fn: FunctionType): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: FunctionType): void {
    this.onTouched = fn;
  }

  setDisabledState(state: boolean): void {
    this.blocked.set(state);
  }

  onModelChange($event: string): void {
    this.onChange($event.toString().trim());
  }
}
