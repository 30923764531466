<ng-container *transloco="let t">
    <div class="detail">
        @if (isMobile()) {
            <ng-container [ngTemplateOutlet]="mobileBoxRef"></ng-container>
        } @else {
            <ng-container [ngTemplateOutlet]="defaultBoxRef"></ng-container>
        }

        <div class="actions">
            @for (action of actions(); track $index) {
                <button 
                    nz-button
                    class="action"
                    [ngClass]="{ dark: action?.dark }"
                >
                    {{ t(action?.title) }}
                </button>
            }
        </div>
    </div>
</ng-container>

<ng-template #priceRef>
    <span class="price">
        {{ card()?.price }}$
    </span>
</ng-template>

<ng-template #mobileBoxRef>
    <div class="content mobile">
        <div class="box">
            <cool-road 
                [locationFrom]="card()?.from" 
                [locationTo]="card()?.to">
            </cool-road>

            <cool-svg-icon 
                [name]="icon()"
                class="icon">
            </cool-svg-icon>
        </div>
        <div class="box">
            <cool-info 
                [time]="card()?.time"
                [type]="'flight'">
            </cool-info>

            <ng-container [ngTemplateOutlet]="priceRef"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #defaultBoxRef>
    <div class="box"> 
        <div class="container">
            <cool-svg-icon 
                [name]="icon()"
                class="icon">
            </cool-svg-icon>

            <div class="content">
                <cool-road 
                    [locationFrom]="card()?.from" 
                    [locationTo]="card()?.to">
                </cool-road>

                <cool-info 
                    [time]="card()?.time"
                    [type]="'flight'">
                </cool-info>
            </div>
        </div>

        <ng-container [ngTemplateOutlet]="priceRef"></ng-container>
    </div>
</ng-template>

