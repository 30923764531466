import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { RoadDataType } from 'src/app/typings';

@Component({
  selector: 'cool-info',
  standalone: true,
  imports: [TranslocoDirective],
  templateUrl: './info.component.html',
  styleUrl: './info.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoComponent {
  type = input<RoadDataType>();
  time = input<string>();
}
