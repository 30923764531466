import { AfterViewInit, DestroyRef, Directive, HostListener, input, model } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject, delay, tap } from 'rxjs';

@Directive({
  selector: '[coolClick]',
  standalone: true,
  exportAs: 'clickState',
})
export class ClickDirective implements AfterViewInit {
  readonly isActive = model<boolean>();
  readonly delay = input<number>(200);

  private clickStream$ = new Subject<void>();

  constructor(private destroyRef: DestroyRef) {}

  ngAfterViewInit(): void {
    this.initStream();
  }

  @HostListener('click', ['$event'])
  protected onClick(): void {
    this.clickStream$.next();
  }

  private toggle(): void {
    this.isActive.set(!this.isActive());
  }

  private initStream(): void {
    this.clickStream$
      .pipe(
        tap(() => this.toggle()),
        delay(this.delay()),
        tap(() => this.toggle()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }
}
