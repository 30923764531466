import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  selector: 'cool-road',
  standalone: true,
  imports: [SvgIconComponent],
  templateUrl: './road.component.html',
  styleUrl: './road.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoadComponent {
  locationFrom = input<string>();
  locationTo = input<string>();
}
