<ng-container *transloco="let t">
    <div 
        coolClick
        #state="clickState"
        [ngClass]="{ 
            disabled: disabled(),
            bounce: state.isActive()
        }"
        class="card"
    >
        <a 
            class="trigger"
            [ngClass]="{ disabled: disabled()}"
            (click)="clicked.emit(card())"
        >
            @if(!small()) {
                <img 
                    nz-image 
                    [nzSrc]="card()?.photo"
                    [alt]="card()?.from"
                    [nzFallback]="'/assets/images/fallback.png'"
                />
            }
    
            <div 
                class="box"
                [ngClass]="{ small: small() }"
            >    
                <div class="content">
                    <div class="container">
                        <cool-road
                            [locationFrom]="card().from"
                            [locationTo]="card().to">
                        </cool-road>

                        <cool-info 
                            [time]="card().time"
                            class="info">
                        </cool-info>
                    </div>

                    <cool-price 
                        [price]="card()?.price"
                        [small]="small()"
                        class="price">
                    </cool-price>
                </div>
    
                @if (learn()) {
                    <span class="learn">{{ t('learn') }}</span>
                }
            </div>
        </a>
    </div>
</ng-container>