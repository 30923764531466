import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CUSTOM_ICONS_TYPE } from '../../../typings';
import { SVG_ICONS } from '../../../constants';


@Component({
  selector: 'cool-svg-icon',
  template: `<ng-content [attr.data-test-id]="testId"></ng-content> `,
  styles: [
    `
      :host {
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      :host ::ng-deep svg {
        fill: currentColor;
        width: 100%;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SvgIconComponent implements OnChanges {
  name = input<CUSTOM_ICONS_TYPE>('c_global');
  testId = input<string>();

  constructor(private element: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['name']) {
      const iconName: CUSTOM_ICONS_TYPE = changes['name'].currentValue;

      this.element.nativeElement.innerHTML = SVG_ICONS[iconName] || null;
    }
  }
}
