import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'cool-price',
  standalone: true,
  imports: [NgClass],
  templateUrl: './price.component.html',
  styleUrl: './price.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceComponent {
  price = input<string | number>();
  small = input<boolean>();
}
