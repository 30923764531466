<ng-container *transloco="let t">
    <div class="info">
        @if (type()) {
            <span class="time"> 
                {{ t('type.title') }}: {{ t('type.' + type())}}
            </span>
        }
    
        <span class="time">
            {{ t('time') }}: {{ time() }}
        </span>
    </div>
</ng-container>