import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import {
  BehaviorSubject,
  catchError,
  delay,
  EMPTY,
  first,
  Observable,
  startWith,
  switchMap,
  tap,
} from 'rxjs';


import { MessageService } from './message.service';
import { MetaService } from './meta.service';
import { StorageService } from './storage.service';
import { ERROR_MESSAGE, LANGUAGE, LANGUAGE_LOCALE, SUCCESS_MESSAGE } from '../../constants';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  #currentLang = new BehaviorSubject<LANGUAGE>(null);

  get currentLang$(): Observable<LANGUAGE> {
    return this.#currentLang.asObservable();
  }

  constructor(
    private translocoService: TranslocoService,
    private storageService: StorageService,
    private i18nService: NzI18nService,
    private metaService: MetaService,
    private messageService: MessageService,
    private router: Router,
  ) {}

  onChangeLang$(lang: LANGUAGE, isMessage: boolean = true): Observable<string> {
    return this.translocoService.setActiveLang(lang).events$.pipe(
      startWith({}),
      first(),
      delay(200),
      switchMap(() => this.metaService.updateTitle$()),
      tap(() => {
        const link = lang === 'en' ? '' : 'ru';
        this.router.navigate([link]).then(() => {
          this.langChangeSuccess(lang, isMessage);
        });
      }),
      catchError(() => this.langChangeError$()),
    );
  }

  private langChangeSuccess(lang: LANGUAGE, isMessage: boolean): void {
    this.#currentLang.next(lang);
    this.i18nService.setLocale(LANGUAGE_LOCALE[lang]);
    this.metaService.updateLocale(lang);

    this.storageService.lang = lang;

    if (isMessage) {
      this.messageService.onNotifySuccess(SUCCESS_MESSAGE.lang);
    }
  }

  private langChangeError$(): Observable<never> {
    this.messageService.onNotifyError(ERROR_MESSAGE.lang);

    return EMPTY;
  }
}
