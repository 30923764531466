import { ChangeDetectionStrategy, Component, forwardRef, input, model } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzSizeLDSType, NzValidateStatus } from 'ng-zorro-antd/core/types';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FunctionType } from '../../../typings';


@Component({
  selector: 'cool-select',
  standalone: true,
  imports: [NzFormModule, FormsModule, NzSelectModule],
  templateUrl: './select.component.html',
  styleUrl: './select.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements ControlValueAccessor {
  value = model<number>();
  placeholder = input<string>();
  blocked = model<boolean>();
  size = input<NzSizeLDSType>('large');
  status = input<NzValidateStatus>('');
  options = input<any[]>([1]);

  onChange: FunctionType = () => {};
  onTouched: FunctionType = () => {};

  writeValue(value: number): void {
    this.value.set(value);
  }

  registerOnChange(fn: FunctionType): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: FunctionType): void {
    this.onTouched = fn;
  }

  setDisabledState(state: boolean): void {
    this.blocked.set(state);
  }

  onModelChange($event: number): void {
    this.onChange($event);
  }
}
