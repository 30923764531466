import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SplashService {
  private cdkBlock = 'cdk-global-scrollblock';

  get splashEl(): Element {
    return this.document.getElementsByTagName('cool-splash')[0];
  }

  get documentEl(): HTMLElement {
    return this.document.documentElement;
  }

  get renderer(): Renderer2 {
    return this.rendererF.createRenderer(null, null);
  }

  constructor(
    @Inject(DOCUMENT) private document: Document, 
    private rendererF: RendererFactory2
  ) {}

  showSplash(): void {
    this.splashEl.classList.remove('hidden');
    this.renderer.addClass(this.documentEl, this.cdkBlock);
  }

  hideSplash(): void {
    this.splashEl.classList.add('hidden');
    this.renderer.removeClass(this.documentEl, this.cdkBlock);
  }
}
