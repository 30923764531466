import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreakpointService {
  #isMobile = new BehaviorSubject<boolean>(false);
  isMobile$ = this.#isMobile.asObservable();

  #isTablet = new BehaviorSubject<boolean>(false);
  isTablet$ = this.#isTablet.asObservable();

  #isDesktop = new BehaviorSubject<boolean>(false);
  isDesktop$ = this.#isDesktop.asObservable();

  #points: string[] = ['(max-width: 767px)', '(min-width: 768px)', '(min-width: 1024px)'];

  constructor(private breakpointObserver: BreakpointObserver) {}

  observe$(): Observable<BreakpointState> {
    return this.breakpointObserver.observe(this.#points).pipe(
      tap((state) => {
        this.#isMobile.next(state.breakpoints['(max-width: 767px)']);
        this.#isTablet.next(state.breakpoints['(min-width: 768px)']);
        this.#isDesktop.next(state.breakpoints['(min-width: 1024px)']);
      }),
    );
  }
}
