<ng-container *transloco="let t">
    <nz-form-item>
        <nz-form-control 
            [nzValidateStatus]="status()"
        >
            <nz-input-group 
                [nzPrefix]="prefix"
                [nzSize]="size()"
                class="group"
            >
                <input 
                    nz-input 
                    [readOnly]="readonly()"
                    [disabled]="blocked()"
                    [(ngModel)]="value"
                    [autocomplete]="autocomplete()"
                    (ngModelChange)="onModelChange($event)"
                />
            </nz-input-group>
        </nz-form-control>
    </nz-form-item>
    
    <ng-template #prefix>
        <cool-svg-icon 
            [name]="'c_location'"
            class="prefix">
        </cool-svg-icon>

        <span 
            class="location"
        >
            {{ t('location') }}:
        </span>
    </ng-template>
</ng-container>