<nz-form-item>
    <nz-form-control 
        [nzValidateStatus]="status()"
    >
        <nz-select 
            nzShowSearch 
            nzAllowClear 
            [nzSize]="size()"
            [disabled]="blocked()"
            [nzPlaceHolder]="placeholder()" 
            [(ngModel)]="value"
            (ngModelChange)="onModelChange($event)"
        >
            @for (option of options(); track $index) {
                <nz-option 
                    [nzLabel]="'Ташкент, Узбекистан'" 
                    [nzValue]="1">
                </nz-option>
            }
        </nz-select>
    </nz-form-control>
</nz-form-item>